<template>
    
        <div>
          <RoleTabs />
            <div class="main-content__container _border-top-transparent">
              <Toolbar>
                <template #start>
                    <h3>
                      {{ $i18n.t('manage_admins') }}
                    </h3>
                </template>
                <template #end>
                  <!--  -->
                <Button @click="$router.push('/role-admins/add')" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('add_admin')"
                        icon="pi pi-plus" class="p-button-sm p-button-success" />
                  </template>
                </Toolbar>
                <DataTable 
                :value="admins" 
                :rows="10" 
                :loading="loadingFlag"
                :scrollable="true" 
                showGridlines 
                responsiveLayout="scroll"
                stripedRows
                :rowClass="disabledAdmin"
          class="p-datatable-sm mt-1">
          <template #empty>
            {{ $t('no_data') }}
          </template>
          <Column field="id" header="ID" :showFilterMenu="false" :styles="{ 'min-width': '10rem' }">
            <template #body="{ data }">
              <div>
                {{ data.id }}
            </div>
            </template>
          </Column>
          <Column field="login" :header="$t('login')" :showFilterMenu="false" :styles="{ 'min-width': '10rem' }">
            <template #body="{ data }">
              <router-link color="var(--main-flamingo)" :to="
              {
                name: 'AdminLogs', params: {login: data.login}
              }"
              >
                {{ data.login && data.login !== null ? data.login : '---' }}
              </router-link>
            </template>
          </Column>
          <!-- <Column field="created_at" :header="$t('dataCreate')" :showFilterMenu="false" :styles="{ 'min-width': '10rem' }">
            <template #body="{ data }">
              <div style="white-space: nowrap">
                {{ new Date(data.created_at).toLocaleString() }}
              </div>
            </template>
          </Column> -->
          <!-- <Column field="is_active" :header="`${$t('off')}/${$t('on')}`" :showFilterMenu="false" :styles="{ 'min-width': '10rem' }">
            <template #body="{ data }">
              <v-switch
              v-model="data.is_active"
              hide-details
              color="green accent-4" inset
              class="banners__active mt-0 custom-switch "
              @change="togglePlayer(data)"
              ></v-switch>
            </template>
          </Column> -->
          <Column field="role" :header="$t('role')" :showFilterMenu="false" :styles="{ 'min-width': '10rem' }">
            <template #body="{ data }">
              <v-chip v-if="data.role" small label
              :color="getLabelColor(data.role.name)"
              style="color: var(--white)"
              >
                {{ roleText(data.role.name) }} 
              </v-chip>
              <v-chip v-if="!data.is_active" 
              small label
              class="ml-1"
              color="var(--red-500)"
              style="color: var(--white)"
              >
                {{ $t('disabled')  }}
              </v-chip>
            </template>
          </Column>
          <Column :exportable="false" :styles="{ 'min-width': '10rem' }">
            <template #body="{ data }">
              <div class="d-flex align-center flex-wrap action-buttons">
                <Button icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-warning mr-2" @click="toEditUser(data)" />
                <Button 
                v-if="data.is_active"
                icon="pi pi-trash" class="p-button-sm p-button-rounded p-button-danger" @click="toDeleteUser(data)" />
              </div>
            </template>
          </Column>
          </DataTable>
          </div>
            <!-- Add User -->
            <!-- Edit User -->
          <!-- Delete Window -->
        <v-dialog
        v-model="toDeleteDialog"
        max-width="550px"
        scrollable
        :persistent="formLoading"
        content-class="br-16"
      >
        <v-card>
          <v-card-title class="font-weight-bold flex-column align-start pb-0">
            <span class="c-red">{{ $i18n.t("user_delete_confirm") }}</span>
          </v-card-title>
          <v-card-text class="pb-0 pt-2" >
            <span class="field text-left d-block">
              <label>{{$t('login')}}</label>
              <InputText
              v-if="deleteUser.login"
              disabled
              class="p-inputtext-sm fw" 
              :value="deleteUser.login" />
          </span>
            <span class="field text-left d-block">
              <label>{{$t('role')}}</label>
              <InputText
              v-if="deleteUser.role && deleteUser.role.name"
              disabled
              class="p-inputtext-sm fw" 
              :value="roleText(deleteUser.role.name)"
              />
          </span>
            <v-switch
                  disabled
                  v-model="deleteUser.is_active"
                  hide-details
                  color="green accent-4" inset
                  class="ml-1 my-2 custom-switch "
                  ></v-switch>
          </v-card-text>
          <v-card-actions>
            <v-col style="text-align: right" class="pa-0">
              <Button @click="confirmDeleteUser" 
              :disabled="formLoading" :loading="formLoading" 
              :label="$t('delete')"
                        class="p-button-sm p-button-danger" />
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
        </div>
      
</template>

<script>
import RoleTabs from '../RoleTabs.vue';
import { mapGetters } from 'vuex';
    export default {
    name: 'ViewRoleAdmins',
    components: { RoleTabs },
    data() {
        return {
            loadingFlag: false,
            toDeleteDialog: false,
            formLoading: false,
            filters: {
                login: '',
                ip: '',
                hash: '',
            },
            deleteUser: {},
            page: 1,
            size: 25,
            activeFilters: {},
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.commit('setPageName', 'manage_admins');
        });
    },
    async mounted() {
        if (this.admins.length === 0) {
            await this.applyFilters();
        }
        this.activeFilters = {};
        this.loadingFlag = false;
    },
    computed: {
        ...mapGetters({
            admins: 'admins/getAdmins',
            roles: 'roles/getRole'
        }),
    },
    methods: {
      disabledAdmin(data){
        return !data.is_active ? 'disabled-row' : '';
      },
        async confirmDeleteUser() {
          this.formLoading = true;
          const id = this.deleteUser.id;
          try {
            await this.$store.dispatch('admins/awaitDeleteAdmin', id);
            this.$toast.add({ severity: 'success', summary: this.$t('admin_deleted'), life: 4000 });
            try {
              await this.applyFilters();
            } catch (error) {
              this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
            }
          } catch (error) {
            this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
            
          } finally{
            this.formLoading = false;
          }
         
          this.toDeleteDialog = false;

        },
        toEditUser(user) {
            // this.editUser = user;
            this.$router.push(
              {

                path: `/role-admins/edit/${user.id}`,

              }
            )
        },
        toDeleteUser(user) {
            this.toDeleteDialog = true;
            this.deleteUser = user;
        },
        roleText(role) {
            switch (role) {
                case 'SUPERUSER':
                    return 'Super user';
                case 'ADMIN':
                    return 'Admin';
                case 'PROMO_MANAGER':
                    return 'Promo manager';
                case 'FINANCIAL_MANAGER':
                    return 'Financial manager';
                case 'GAME_MANAGER':
                    return 'Game manager';
                case 'PRODUCT_MANAGER':
                    return 'Product manager';
                case 'CFO':
                    return 'CFO';
                case 'CRM_MANAGER':
                    return 'CRM manager';
            }
        },
        getLabelColor(role){
      switch(role){
        case 'SUPERUSER':
        return 'var(--red-500)';
        case 'PROMO_MANAGER':
        return 'var(--indigo-500)';
        case 'FINANCIAL_MANAGER':
        return 'var(--pink-500)';
        case 'GAME_MANAGER':
        return 'var(--orange-500)';
        case 'PRODUCT_MANAGER':
        return 'var(--cyan-500)';
        case 'CFO':
        return 'var(--blue-500)';
        case 'CRM_MANAGER':
        return 'var(--yellow-500)';
      }
    },
        togglePlayer(player) {
            const data = {
                id: player.id,
                status: player.is_active,
            };
            // this.$store.dispatch('updatePaymentSystemStatus', data)
        },
        async applyFilters() {
            if (!this.loadingFlag) {
                this.loadingFlag = true;
                //this.filters.page = this.page;
                //this.filters.size = this.filterPages;
                //this.filters.page = this.page = 1;
                await this.$store.dispatch('admins/awaitGetAdmins');
                // for (const [key, value] of Object.entries(this.filters)) {
                //     if (value !== '') {
                //         this.$set(this.activeFilters, key, value);
                //     }
                //     else {
                //         this.$delete(this.activeFilters, key, value);
                //     }
                // }
                this.loadingFlag = false;
            }
        },
    },
    
}
</script>

<style lang="scss" scoped>
.action-buttons {
    gap: 5px;
		&__button {

            min-width: 30px!important;
            height: 30px!important;
		}
}


</style>